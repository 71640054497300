<template>
  <div class="review-container">
    <!-- 导航栏 -->
    <van-nav-bar
      v-if="isToken === 'true'"
      :class="!$isLinkGame ? 'black-bc yellow-color' : 'red-bc white-color'"
    >
      <i slot="left" class="iconfont icon-jiantou1" @click="$router.back()"></i>
      <!-- :class="islightHieght ? 'lightHeight' : 'notlightHeight'" -->
      <van-button
        slot="right"
        @click="clickReview"
        :class="{ 'yellow-color': !$isLinkGame, 'black-bc': !$isLinkGame }"
        >Nhận xét</van-button
      >
    </van-nav-bar>
    <!-- 已经登录了之后发表评论的 -->
    <van-field
      v-if="isToken === 'true'"
      v-model="commetns"
      rows="1"
      autosize
      type="textarea"
      placeholder="nhận xét..."
    />
    <!-- 没有登陆时 -->
    <div v-else class="error-box">
      <span class="white"></span>
      <span class="black"></span>
      <div class="blacktext">
        Đã xảy ra lỗi trên trang bạn đã truy cập!(404)
      </div>
    </div>
  </div>
</template>

<script>
import { publicReivew } from '@/api/comments.js'
export default {
  name: 'DiscoverReview',
  components: {},
  props: {},
  data() {
    return {
      commetns: '',
      isToken: this.$route.query.isToken,
      id: this.$route.query.id,
      islightHieght: false,
    }
  },
  computed: {},
  watch: {
    commetns(val) {
      if (val.length >= 4) {
        return (this.islightHieght = true)
      }
    },
  },
  created() {
    if (this.$route.query.isToken === 'false') {
      this.$toast('Vui lòng đăng nhập trước')
    }
  },
  mounted() {},
  methods: {
    async clickReview() {
      if (this.islightHieght) {
        const {
          data: { ret, msg, data },
        } = await publicReivew({
          id: this.id,
          content: this.commetns,
        })
        if (ret === 1) {
          this.$router.back()
          this.$toast(msg)
        } else {
          this.$toast(msg)
        }
      } else {
        this.$toast('Nhập ít nhất 5 ký tự.')
      }
    },
  },
}
</script>

<style lang="less" scoped>
.review-container {
  .van-nav-bar {
    width: 100%;
    height: 100px;
    position: fixed;
  }
  /deep/ .van-nav-bar__content {
    // background: #ff0000;
    .iconfont {
      font-size: 44px;
      // color: #fff;
    }
  }
  .van-button {
    width: 200px;
    height: 60px;
    border: 0;
    color: #ff0000;
  }
  .notlightHeight {
    background-color: #f08a38;
  }
  .lightHeight {
    background-color: #fdda46;
  }
  .van-field {
    padding-top: 100px;
    height: 700px;
  }
  .error-box {
    width: 100%;
    margin-top: 300px;
    span {
      display: inline-block;
      width: 50%;
      height: 100px;
    }
    .white {
      background: #fff;
    }
    .black {
      background: #000;
    }
    .blacktext {
      background: #000;
      margin-top: -15px;
      width: 100%;
      height: 300px;
      line-height: 500px;
      text-align: center;
      font-size: 40px;
      color: #0c97e7;
    }
  }
}
</style>
